import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../constants/API";
import { downloadExcelData } from "../../common-components/commonFunctions";

interface isbnObj {
  _id?: string;
  ISBN?: string;
  FULL_TITLE?: string;
}

interface pdfIsbnsInterface {
  statusCode:number;
  isbns:isbnObj[];
}

interface excelInitialState {
  data:[],
  pdfIsbns:pdfIsbnsInterface,
  isLoading: boolean,
  isError: boolean,
  status: string,
  statusCode: number,
  message: '',
  isUploadLoading: boolean,
}

const initialState : excelInitialState={
    data:[],
    pdfIsbns:{
      statusCode:0,
      isbns:[]
    },
    isLoading: false,
    isError: false,
    status: "",
    statusCode: 0,
    message: '',
    isUploadLoading: false,
}
export const postExcelData = createAsyncThunk(
    "postExcelData",
    async (data: any, { rejectWithValue }) => {
      try {
        const response = await axios.post(API.POSTEXCELDATA, data,{ responseType: "blob" });
        return response;
      } catch (err: any) {
        return rejectWithValue(err.response);
      }
    }
  );
export const postPdfData = createAsyncThunk(
    "postPdfData",
    async (data: any, { rejectWithValue }) => {
      try {
        const formdata=new FormData();
        formdata.append("file",data.file);
        formdata.append("isbn",data.isbnData)
        const response = await axios.post(API.POSTPDFDATA, formdata);
        return response;
      } catch (err: any) {
        return rejectWithValue(err.response);
      }
    }
  );
const excelSlice=createSlice({
    name:"excelSlice",
    initialState,
    reducers:{
      emptyPdfIsbnsIsbns:(state) => {
        state.pdfIsbns = initialState.pdfIsbns;
      }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(postExcelData.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
            state.status = "loading";
        })
        .addCase(postExcelData.fulfilled,(state,action)=>{
            state.isLoading = false;
            downloadExcelData(action.payload.data)
            state.status = "succeeded";
            state.statusCode = action.payload.status;
        })
        .addCase(postExcelData.rejected,(state)=>{
            state.isLoading = false;
        })
        .addCase(postPdfData.pending,(state)=>{
            state.isLoading = true;
            state.isError = false;
            state.status = "loading";
        })
        .addCase(postPdfData.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.pdfIsbns.isbns=action.payload.data;
            state.pdfIsbns.statusCode = action.payload.status;
            state.status = "succeeded";
            state.statusCode = action.payload.status;
        })
        .addCase(postPdfData.rejected,(state)=>{
            state.isLoading = false;
        })
    }
})

export const {emptyPdfIsbnsIsbns} = excelSlice.actions;

export default excelSlice.reducer;