export const API = {
  CREATEBUDGETREQ: "/budgetRequest/createBudgetReq",
  // LOGINUSER: "/user/loginUser",
  LOGINUSER : '/invite/azure-local-token',
  fetchUserInfo: "/user/fetchUserInfo",
  LOGOUTUSER: "/user/logoutUser",
  ADDUSER: "/user/addUser",
  FETCHROLES: "/role/fetchRoles",
  FETCHUSERS: "/user/fetchUsers",
  SETPASSWORD: "/user/setPassword",
  CHECKONBOARDING: "/user/onBoarding",
  CHECKFORGOTTOKEN: "/user/checkForgotToken",
  SENDFORGOTMAIL: "/user/sendForgotMail",
  FETCHDASHBOARD: "/budget/get_accounts_dashboard",
  FETCHACCOUNTS: "/budget/get_accounts",
  FETCHISBNS: "/budget/compare_ISBN",
  ADDNEWBUDGETREQUEST: "/budget/add_budget",
  GETALLBUDGETS: "/budget/get_all_budgets",
  GETBUDGETBYID: "/budget/get_budget_by_id",
  FETCHISBNSLIST: "/budget/isbn_list",
  FETCHISBNBYCOMMASEPARATED: "/budget/compare_comma_separated",
  FETCHAPPROVALDECLINEDDATA: "/budget/approve_budget_request",
  CHECKACCOUNTBUDGET: "/budget/check-account-budget",
  POSTACCOUNTDETAILS: "/budget/account_detiels",
  SENDONBOARDINGMAIL: "/user/sendOnboardingMail",
  FETCHLOGINUSERNOTIFICATION: "/notifications/fetchAllUnreadNotifications",
  UPDATENOTIFICATIONSTATUS: "/notifications/markNotificationsAsRead",
  GETSELECTEDINVOICE: "/Invoices/invoices",
  POSTSELECTEDINVOICE: "/Invoices/match_invoice",
  GETINVOICES: "/Invoices/get_invoices",
  POSTINVOICES: "/Invoices/add_invoices",
  GETCOOPSUMMARY: "/budget/get_coop_summary_data",
  POSTSEARCHINVOICE: "/Invoices/search_invoices",
  READINVOICEFILE: "/Invoices/read_invoice_file",
  POSTEXCELDATA:"/budget/downloadIsbns",
  POSTPDFDATA:"/budget/uploadIsbnPdf"
};
